<template>
  <div>
    <div :id="pageClassName" class="mainContents">
      <UserNavSp />

      <UserTitle :user="user" />

      <UserNav :user="user" />

      <div id="grid" class="gridArea">
        <p v-if="isOwner(user.id)" class="addEpisode">
          <router-link :to="{ name: 'episode-create' }">
            新しいエピソードを追加
          </router-link>
        </p>
        <div
          v-masonry="containerId"
          class="grid"
          transition-duration="0.3s"
          item-selector=".item"
        >
          <EpisodeCard
            v-for="episode in episode.episodes"
            :key="episode.node.id"
            :episode="episode.node"
            :edit-mode="true"
            @select-tag="onSelectTag"
            @show-delete-episode-modal="onShowDeleteEpisodeModal"
          />
        </div>
        <!-- /#grid -->
      </div>

      <div class="buttonArea">
        <p>
          <button
            v-if="episode.hasNextPage"
            type="submit"
            @click="getMoreEpisodes($event)"
          >
            もっと見る
          </button>
        </p>
        <!-- /.buttonArea -->
      </div>

      <div v-if="canSendMessage" class="messageArea">
        <div class="inner">
          <h2>
            <span>{{ user.full_name }}</span>さんへのメッセージ
          </h2>
          <template v-if="!completed">
            <div class="textareaFrame">
              <textarea
                v-model="message.message"
                placeholder="コメント入力…"
                :class="{ 'text-danger': !validMessage }"
              />
            </div>
            <p class="button">
              <button
                type="submit"
                :disabled="formInvalid"
                @click="send($event)"
              >
                送信
              </button>
            </p>
          </template>
          <template v-else>
            送信しました
          </template>
        </div>
        <!-- /.messageArea -->
      </div>
      <!-- /.mainContents -->
    </div>

    <modal name="delete-episode">
      <div id="modalDelete" class="modal">
        <div class="modalOverlay" />
        <div class="modalInner">
          <p class="caution">
            エピソードを削除します。<br>
            完全に削除されますので、確認の上、削除をおこなってください。
          </p>
          <div class="buttonArea">
            <div class="inner">
              <p class="cancel">
                <a class="closeModal" @click="onCloseModal">キャンセル</a>
              </p>
              <p class="submit">
                <button type="submit" @click="onDeleteEpisode">
                  削除する
                </button>
              </p>
            </div>
          </div>
          <p class="close">
            <a class="closeModal" @click="onCloseModal" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import EpisodeCard from '@/components/EpisodeCard.vue'
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import { mapState } from 'vuex'
import store from '@/store/index'
import { authComputed } from '@/store/helpers.js'
import NProgress from 'nprogress'
import { fieldNotNull } from '@/helpers/validator.js'

export default {
  components: {
    EpisodeCard,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      episodes: [],
      user: {},
      containerId: '',
      message: {},
      isUploading: false,
      completed: false,
      isBlocked: false,
      deleteEpisode: null
    }
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    store.dispatch('episode/setUser', routeTo.params.id).then(() => {
      this.getPageEpisodes()

      store.dispatch('user/fetchUser', { id: routeTo.params.id }).then(user => {
        if (user) {
          this.user = user
          next()
        } else {
          next({ name: '404', params: { resource: 'user' } })
        }
      })
    })
  },
  computed: {
    ...authComputed,
    ...mapState(['episode']),
    canSendMessage: function () {
      if (!this.$store.getters.loggedIn) {
        return false
      }

      if (this.$store.getters.isOwner(this.$route.params.id)) {
        return false
      }

      if (this.isBlocked) {
        return false
      }

      return true
    },
    pageClassName: function () {
      if (this.$store.getters.isOwner(this.$route.params.id)) {
        return 'pageEditUserTop'
      }
      return 'pageUserTop'
    },
    validMessage: function () {
      return fieldNotNull(this.message.message)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validMessage) {
        return true
      }

      return false
    }
  },
  created: function () {
    if (this.u) {
      this.user = this.u
      this.message.user_id = this.user.id
    }

    if (this.$store.getters.loggedIn) {
      this.$store
        .dispatch('user/isBlockedFrom', this.$route.params.id)
        .then(res => {
          this.isBlocked = res.isBlockedFrom.blocked
        })
    }
  },
  beforeDestroy () {},
  mounted () {
    store.dispatch('episode/setUser', this.user.id).then(() => {
      this.getPageEpisodes()
    })
  },
  methods: {
    getPageEpisodes () {
      store.dispatch('episode/clearFilter').then(() => {
        store.dispatch('episode/fetchEpisodes', {
          first: 12,
          page: 'user'
        })
      })
    },
    getMoreEpisodes (event) {
      event.preventDefault()
      const args = {
        first: 12,
        after: this.episode.endCursor,
        add: true,
        page: 'user'
      }
      store.dispatch('episode/fetchEpisodes', args)
    },
    send (event) {
      event.preventDefault()
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('user/sendMessage', this.message)
        .then(res => {
          this.isUploading = false
          NProgress.done()
          if (res.data.errors) {
            this.errors = res.data.errors
          } else {
            this.completed = true
          }
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
        })
    },
    onSelectTag (tag) {
      this.$emit('select-tag', tag)
    },
    onShowDeleteEpisodeModal (episode) {
      this.deleteEpisode = episode
      this.$modal.show('delete-episode')
    },
    onCloseModal () {
      this.$modal.hide('delete-episode')
    },
    onDeleteEpisode () {
      this.$store
        .dispatch('episode/deleteEpisode', this.deleteEpisode)
        .then(() => {
          this.$modal.hide('delete-episode')
        })
        .catch(() => {})
    }
  }
}
</script>

<style>
.modal {
  display: block;
}
.v--modal {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
</style>
